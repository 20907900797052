import React, { useRef } from "react";
import "../styles/_Gallery.scss";

const urlPrefix = "./assets/img/gallery/";

// =======================================================
// ModalGalleryPicture class (ES6)
// =======================================================
class ModalGalleryPicture extends React.Component {
  constructor() {
    super();
    this.state = {
      windowwidth: window.innerWidth,
    };
  }

  handleClick = (event) => {
    //console.log(this.props.modal);
    this.props.modal.ToggleModal();
  };

  render() {
    return (
      <>
        {this.props.show && (
          <div
            id="gallery_modal"
            className="gallery_modal"
            onClick={(event) => this.handleClick(event)}
          >
            <span
              className="close_modal"
              // onClick={(event) => this.handleClick(event)}
            >
              &times;
            </span>
            <img
              className="gallery_modal_content"
              id="img01"
              alt={this.props.cardItem.name}
              src={
                this.state.windowwidth > 769
                  ? urlPrefix + this.props.cardItem.url
                  : urlPrefix + this.props.cardItem.url_mobile
              }
            ></img>

            <div id="caption">{this.props.cardItem.name}</div>
          </div>
        )}
      </>
    );
  }
}

// =======================================================
export default ModalGalleryPicture;
