import React, { useState, useEffect, useRef } from "react";
import "../styles/_Section.scss";

// =======================================================
// useOnScreen utility
// =======================================================
export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

// =======================================================
// Section class as Javascript function
// =======================================================
function Section(props) {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  const blankSpace = " ";

  return (
    <div className={props.className + (isOnScreen ? blankSpace + "show" : blankSpace + "hidden")} id={props.id} ref={elementRef} style={props.style}>
      {props.elements}
    </div>
  );
}

export default Section;
