import React from "react";
import ModalGalleryPicture from "./ModalPicture";
import galleryDB from "../galleryDB";
import { v4 as uuid } from "uuid";
import "../styles/_Gallery.scss";

const urlPrefix = "./assets/img/gallery/";

// =======================================================
// GalleryCard class (ES6)
// =======================================================
function GalleryCard({ cardItem, collectionName, owner }) {
  const handleClick = (event) => {
    owner.ToggleModal(cardItem);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  // --------------------
  // Main rendering
  // --------------------
  return (
    <div className="galleryCard">
      <div className="galleryClass_header">
        <p>{cardItem.name}</p>
      </div>
      <div className="galleryCard_img_container">
        <img
          className="galleryCard_img"
          src={urlPrefix + cardItem.previewUrl}
          alt={"GalleryCard " + cardItem.name}
          width={100}
          height={150}
          onClick={(event) => handleClick(event)}
          onContextMenu={handleRightClick}
        />
      </div>
      <div className="galleryCard_footer">
        <p>
          {cardItem.technical}, {cardItem.width} * {cardItem.height} cm (
          {cardItem.year})
        </p>
        {/*
        <p>Technique: {cardItem.technical}</p>
        <p>
          Taille: {cardItem.width} X {cardItem.height} cm
        </p>*/}
      </div>
    </div>
  );
}

// =======================================================
// CardsContainer class (ES6)
// =======================================================
function CardsContainer({ collection, owner }) {
  return (
    <div className="galleryCollection">
      <h3>{collection.name}</h3>
      <div className="gallery_cards_container">
        {collection.paints.map((item) => {
          return (
            <GalleryCard
              key={item.id}
              cardItem={item}
              collectionName={collection.name}
              owner={owner}
            />
          );
        })}
      </div>
    </div>
  );
}
// =======================================================
// GalleryContainer class (ES6)
// =======================================================
class GalleryContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      cardItem: null,
    };
  }

  // --------------------
  // ToggleModal
  // --------------------
  ToggleModal = (item) => {
    this.setState((prevState) => ({ cardItem: item }));
    this.setState((prevState) => ({ modal: prevState.modal ? false : true }));
  };

  // --------------------
  // Main rendering
  // --------------------
  render() {
    return (
      <div className="gallery_container">
        <ModalGalleryPicture
          show={this.state.modal}
          modal={this}
          cardItem={this.state.cardItem}
          key={uuid()}
        />
        {galleryDB.map((item) => {
          return (
            <CardsContainer key={item.id} collection={item} owner={this} />
          );
        })}
      </div>
    );
  }
}

// =======================================================
export default GalleryContainer;
