import React from "react";
import { v4 as uuid } from "uuid";
import "../styles/_SocialBar.scss";

// =======================================================
// NavBar class as Javascript function
// =======================================================
const SocialBar = () => {
  return (
    <div className="social_bar" key={uuid()}>
      <a
        // className="social_btn"
        className="social_btn"
        id="social_btn_fb"
        href="https://www.facebook.com/ben.moisette/"
        target="_blank"
        rel="noreferrer"
        key={uuid()}
      >
        <i className="fab fa-facebook-f" key={uuid()}></i>
      </a>
      <a
        className="social_btn"
        id="social_btn_insta"
        href="https://www.instagram.com/benmoisette/?hl=fr"
        target="_blank"
        rel="noreferrer"
        key={uuid()}
      >
        <i className="fab fa-instagram" key={uuid()}></i>
      </a>
    </div>
  );
};

// =======================================================
// NavBar class as Javascript function
// =======================================================
export const SocialBarHeader = () => {
  return (
    <div className="social_bar_header" key={uuid()}>
      <a
        // className="social_btn"
        className="social_btn"
        id="social_btn_fb"
        href="https://www.facebook.com/ben.moisette/"
        target="_blank"
        rel="noreferrer"
        key={uuid()}
      >
        <i className="fab fa-facebook-f" key={uuid()}></i>
      </a>
      <a
        className="social_btn"
        id="social_btn_insta"
        href="https://www.instagram.com/benpastre/?hl=fr"
        target="_blank"
        rel="noreferrer"
        key={uuid()}
      >
        <i className="fab fa-instagram" key={uuid()}></i>
      </a>
    </div>
  );
};

export default SocialBar;
