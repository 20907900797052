import React from "react";
import "../styles/_NavBar.scss";

// =======================================================
// NavBar class as Javascript function
// =======================================================
function NavBar(props) {
  return (
    <div className="NavBar">
      {/* <a href="#homeSection">Home</a> */}
      <a href="#gallerySection">Galerie</a>
      <a href="#aboutSection">Démarche</a>
      <a href="#activitySection">Actualités</a>
      <a href="#contactSection">Contact</a>
    </div>
  );
}

export default NavBar;
