import React from "react";
import { v4 as uuid } from "uuid";
import NavBar from "./NavBar";
import SocialBar, { SocialBarHeader } from "./SocialBar";
import ToTop from "./ToTop";
import Section from "./Section";
import GalleryContainer from "./Gallery";
import "../styles/_App.scss";
import "../styles/_Header.scss";
import "../styles/_About.scss";
import "../styles/_Activity.scss";
import "../styles/_Contact.scss";
//import RGPDPopup from "./RGPDPopup";

// =======================================================
// App class (ES6)
// =======================================================
class App extends React.Component {
  handleRightClick = (event) => {
    event.preventDefault();
  };
  // -----------------------------------------------------
  // NavBar rendering
  // -----------------------------------------------------
  renderNavBar() {
    return (
      <>
        <NavBar />
      </>
    );
  }

  // -----------------------------------------------------
  // Header rendering
  // -----------------------------------------------------
  renderHeader2() {
    const myElement = [
      <h1 key={uuid()} className="black_Han_Big">
        {this.props.appName}
      </h1>,
      <h2 key={uuid()}>Artiste Peintre</h2>,
      <div key={uuid()} className="pictTextContainer">
        <img key={uuid()} className="main_pict" src={process.env.PUBLIC_URL + "/assets/img/PORTRAIT_ATELIER.png"} alt="Atelier" />
        <div key={uuid()} className="overlay">
          <p className="description_Text">
            "Le crépuscule, Bathyscaphe qui conduit vers un état intérieur des profondeurs.<br></br>
            Le crépuscule, Métaphore de l’introspection, L’envers du moi.
            <br></br>
            <br></br>
            Comme un moment de refuge, <br></br>
            Comme un moment de recul, <br></br>
            Comme ressource, <br></br>
            <br></br>
            Il libère la perspective, <br></br>
            Il ouvre l’esprit, <br></br>
            Il permet, paradoxalement d’y voir plus clair.<br></br>
            <br></br>
            La peinture, un endroit sûr, <br></br>
            La couture, pour réparer les blessures, <br></br>
            La broderie métallisée, pour interpeller."
          </p>
        </div>
      </div>,
      <SocialBarHeader key={uuid()} />,
    ];

    return (
      <Section
        className="App_header _section"
        id="homeSection"
        //key={uuid()}
        elements={myElement}
      />
    );
  }
  renderHeader() {
    const myStyles = {
      position: "absolute",
      top: 300,
      left: 0, // Stretch from the left edge
      right: 0, // to the right edge
      margin: "0 auto", // Auto margins will center the image
      zIndex: 0,
      height: 558,
      width: "auto", // Corrected the typo here
    };

    const textStyle = {
      height: "-webkit-fill-available",
    };

    const myElement = [
      <h1 key={uuid()} className="black_Han_Big">
        {this.props.appName}
      </h1>,
      <img key={uuid()} className="main_pict" style={myStyles} src={process.env.PUBLIC_URL + "/assets/img/PORTRAIT_ATELIER.png"} alt="Atelier" />,
      <div key={uuid()} className="description">
        <div style={textStyle} key={uuid()}>
          <blockquote className="description_Text" key={uuid()}>
            "Le crépuscule, Bathyscaphe qui conduit vers un état intérieur des profondeurs.<br></br>
            Le crépuscule, Métaphore de l’introspection, L’envers du moi.
            <br></br>
            <br></br>
            Comme un moment de refuge, <br></br>
            Comme un moment de recul, <br></br>
            Comme ressource, <br></br>
            <br></br>
            Il libère la perspective, <br></br>
            Il ouvre l’esprit, <br></br>
            Il permet, paradoxalement d’y voir plus clair.<br></br>
            <br></br>
            La peinture, un endroit sûr, <br></br>
            La couture, pour réparer les blessures, <br></br>
            La broderie métallisée, pour interpeller."
          </blockquote>
        </div>
      </div>,
      <h2 key={uuid()} className="Italiana">
        <span>Artiste </span>Peintre<span></span>
      </h2>,
      <SocialBarHeader key={uuid()} />,
    ];

    return (
      <Section
        className="App_header _section"
        id="homeSection"
        //key={uuid()}
        elements={myElement}
      />
    );
  }

  // -----------------------------------------------------
  // gallerySection rendering
  // -----------------------------------------------------
  renderGallerySection() {
    const myElement = [<GalleryContainer key={uuid()} />];

    return (
      <>
        <Section className="_section gallery_section" id="gallerySection" elements={myElement} />
      </>
    );
  }

  // -----------------------------------------------------
  // AboutSection rendering
  // -----------------------------------------------------
  renderAboutSection() {
    const myElement = [
      <div className="about_parallax" key={uuid()} style={{ backgroundImage: `${process.env.PUBLIC_URL}/img/logo.png` }}></div>,

      <h3 className="demarche_title" key={uuid()}>
        Démarche
      </h3>,
      <blockquote className="sensibilisation_Text" key={uuid()}>
        "Mes intentions artistiques s’articulent autour de l’heure bleue, ce moment flottant, entre chiens et loups, céleste et terrestre en même temps où la
        quiétude lutte avec l’inquiétude. <br></br>Il y a quelque-chose de l’ordre de l’enchantement magique, lorsqu’un ciel hésite entre le ciel du jour
        déclinant et le noir profond de la nuit. <br></br>Je peins à l’huile à partir d’esquisses dessinées, de photographies, par des processus lents de glacis
        répétés, patiemment construits et dérivants dans un étrange moment où l’obscurité se répand alors sur la toile comme à la surface des choses. <br></br>
        Ma peinture, faite avec de la couleur et du temps, comme une invitation à ralentir, cherche à faire percevoir l’épaisseur de ce moment comme un
        instantané lent, réflexif. <br></br>Plongez, c'est un monologue intérieur et contemplatif. "
      </blockquote>,

      <div className="contact_video" key={uuid()}>
        <video id="video_about" controls autoPlay muted loop onContextMenu={this.handleRightClick}>
          <source src="assets/video/page-accueil-site-1.mp4" type="video/mp4"></source>
        </video>
      </div>,
    ];
    return <Section className="_section about_container" id="aboutSection" elements={myElement} />;
  }

  // -----------------------------------------------------
  // renderActivitySection rendering
  // -----------------------------------------------------
  renderActivitySection() {
    const myElement = [
      <h3 key={uuid()}>Expositions et présences</h3>,
      <p className="about_title activity_Title" key={uuid()}>
        Présences :
      </p>,
      <p className="activity_Text" key={uuid()}>
        CHINE exposition collective permanente au musée d’Art international de TUCHENG. Exposition collective permanente au musée d’Art international de LONGZOU
      </p>,
      <p className="activity_Text" key={uuid()}>
        ETATS UNIS exposition permanente à la Galerie BESHARAT, ATLANTA
      </p>,
      <br key={uuid()}></br>,
      <br key={uuid()}></br>,
      <p className="activity_Title" key={uuid()}>
        Dernières expositions :
      </p>,
      <p className="activity_Text" key={uuid()}>
        Octobre 2023 exposition à la Fondation Taylor
      </p>,
      <p className="activity_Text" key={uuid()}>
        Nov 2018 exposition à la Galerie BESHARAT, ATLANTA, USA
      </p>,
      <p className="activity_Text" key={uuid()}>
        Juillet 2017 exposition au salon « Art en Perche », NOCÉ,
      </p>,
      <p className="activity_Text" key={uuid()}>
        Exposition au salon « Passage à l’Art », CHERBOURG,
      </p>,
      <p className="activity_Text" key={uuid()}>
        Avril 2017 exposition au Musée d’Art Moderne de Nanning, CHINE
      </p>,
      <p className="activity_Text" key={uuid()}>
        Déc 2016 exposition au Carreau du Temple, PARIS,
      </p>,
      <p className="activity_Text" key={uuid()}>
        Avril 2016 exposition au musée d’Art de Zunyi, CHINE
      </p>,
      <p className="activity_Text" key={uuid()}>
        Déc 2015 exposition au Salon National des Beaux Arts, Carrousel du Louvre, PARIS
      </p>,

      <div className="contact_video" key={uuid()}>
        <video id="video_contact" controls autoPlay muted loop onContextMenu={this.handleRightClick}>
          <source src="assets/video/vid.mp4" type="video/mp4"></source>
        </video>
      </div>,
    ];
    return <Section className="_section activitySection" id="activitySection" elements={myElement} />;
  }

  // -----------------------------------------------------
  // ContactSection rendering
  // -----------------------------------------------------
  renderContactSection() {
    const myElement = [
      <h3 key={uuid()}>Contact</h3>,
      <div className="contact_container" key={uuid()}>
        <div className="contact_content" key={uuid()}>
          <h4 className="contact_title">Pour toutes questions, remarques, commentaires ou demande de prix, merci de me contacter.</h4>
          <p>
            <i className="fa fa-envelope">&nbsp;&nbsp;</i>
            <a href="mailto:ben.moisette@gmail.com" target="_blank" rel="noopener noreferrer">
              ben.moisette@gmail.com
            </a>
          </p>
          <p>
            <i href="tel:+33646608459" className="fa fa-phone">
              &nbsp;&nbsp;
            </i>
            +33 6 46 60 84 59
          </p>
        </div>

        <SocialBar key={uuid()} />
        <div className="contact_pict_container">
          <img
            className="contact_pict"
            src={process.env.PUBLIC_URL + "/assets/img/ContactPict.webp"}
            alt="profile"
            width={150}
            height={423}
            onContextMenu={this.handleRightClick}
          />
          <img
            className="contact_pict"
            src={process.env.PUBLIC_URL + "/assets/img/JUSTE-DE-PASSAGE.webp"}
            alt="profile"
            width={150}
            height={423}
            onContextMenu={this.handleRightClick}
          />
          <img
            className="contact_pict"
            src={process.env.PUBLIC_URL + "/assets/img/SVALBARD.webp"}
            alt="profile"
            width={150}
            height={423}
            onContextMenu={this.handleRightClick}
          />
        </div>
      </div>,
    ];
    return <Section className="_section contactSection" id="contactSection" elements={myElement} />;
  }

  // -----------------------------------------------------
  // Main rendering
  // -----------------------------------------------------
  render() {
    const content_style = {
      maxWidth: 1500,
      left: 0, // Stretch from the left edge
      right: 0, // to the right edge
      margin: "0 auto",
    };

    return (
      <div className="App">
        {/* <RGPDPopup /> */}
        <ToTop />

        {this.renderNavBar()}
        {this.renderHeader2()}
        {this.renderGallerySection()}
        {this.renderAboutSection()}
        {this.renderActivitySection()}
        {this.renderContactSection()}
      </div>
    );
  }
}

// =======================================================
export default App;
