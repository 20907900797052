import React, { useEffect, useRef, useState } from "react";
import "../styles/_ToTop.scss";

// =======================================================
// ToTop class as Javascript function
// =======================================================
const ToTop = () => {
  const [visible, setVisible] = useState(false);
  const buttonElement = useRef(null);

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // ----------------- Hooks --------------------
  useEffect(() => {
    //console.log(buttonElement.current);

    window.addEventListener("scroll", () => {
      if (buttonElement.current) {
        /*let viewportOffset = buttonElement.current.getBoundingClientRect();
        //console.log(window.pageYOffset + buttonElement.current.offsetTop);
        // Get the pixel color at this position
        let top = window.pageYOffset + buttonElement.current.offsetTop;
        let left = viewportOffset.left + 25;

        let el = document.elementFromPoint(left, top);
        console.log(el);*/
        /*if (el.length() > 0) {
          let style = window.getComputedStyle(el[0]);
        }*/
        //console.log("X: " + left + " Top: " + top);
        //console.log("Style: " + style);
      }

      if (window.pageYOffset > 40) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });

    // cleanup this component
    return () => {
      window.removeEventListener("change", null);
    };
  }, []);

  // ----------------- Render -------------------
  return (
    <>
      {visible && (
        <button
          className={
            visible
              ? "to_top_btn_visible to_top_btn_middle"
              : "to_top_btn_unVisible to_top_btn_middle"
          }
          id="to_top_btn"
          onClick={topFunction}
          ref={buttonElement}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
    </>
  );
};

export default ToTop;
